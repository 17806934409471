<template>

  <router-link path="/thank-you">Thank you page</router-link>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
}
</script>
