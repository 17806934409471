// main.ts
import { createApp } from "vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import router from "./router";
import App from "./App.vue";
import VuePaycard from "vue-paycard";
// import VuePhoneNumberInput from "vue-phone-number-input";
// import "vue-phone-number-input/dist/vue-phone-number-input.css";
import "maz-ui/css/main.css";
import MazBtn from "maz-ui/components/MazBtn";
import MazInput from "maz-ui/components/MazInput";

import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import vueCountryRegionSelect from "vue3-country-region-select";

const app = createApp(App);

app.component("MazBtn", MazBtn);
app.component("MazInput", MazInput);
app.component("MazPhoneNumberInput", MazPhoneNumberInput);
// app.component("vue-phone-number-input", VuePhoneNumberInput);
app.use(vueCountryRegionSelect);
app.use(VuePaycard);
app.use(ElementPlus);
app.use(router);
app.mount("#app");
