import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/thank-you/:id',
    name: 'Thank You',
    component: ()=> import('../views/ThankYou.vue')
  },
  {
    path: '/payment-success/:id',
    name: 'Payment Success',
    component: ()=> import('../views/PaymentSuccess.vue')
  },
  {
    path: '/private-payment/:id',
    name: 'Private Payment Form',
    component: ()=> import('../views/PrivatePaymentForm.vue')
  },
  {
    path:'/reservation',
    name:'Reservation',
    component: ()=> import('../views/ReservationForm.vue')
  },
  {
    path:'/pcr-request-form',
    name:'PCR Request Form',
    component: ()=> import('../views/PCRRegisterForm.vue')
  },
  {
    path:'/airport-transport',
    name:'airport-transport',
    component: ()=> import('../views/AirportTransport.vue')
  },
  {
    path:'/pay-link',
    name:'pay-link',
    component: ()=> import('../views/PayLink.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]
const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router
